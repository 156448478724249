import React,{useEffect} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "lib-flexible";
import AppRouter from "./router";
import store from "./redux/store";
import { Provider } from "react-redux";
import {
  decodeQuery,
  getRandomStr,
  isMobile,
  getCurrentEnv,
  logi,
} from "./utils/tools";
import Tracert from "./utils/tracert";
import SimpleRRWeb from "./utils/simpleRRweb";
/**
 * 待打点必备参数生成完成后再创建实例
 */
window.tracert = new Tracert();


if (["test", "dev", "preonline"].includes(getCurrentEnv())) {
   const vConsole = new window.VConsole();
   console.log(vConsole);
}

const handleVisibilityChange = () => {
  if (document.hidden) {
    logi('Page is now hidden ',location.pathname);
    // Logic for when the page goes into the background
   // window.simpleRRWeb?.addCustomEvent('pageHidden',{path:location.pathname});
    window.simpleRRWeb?.pause();
   

  } else {
    logi('Page is now visible ',location.pathname);
    // Logic for when the page comes back into the foreground
   // window.simpleRRWeb?.addCustomEvent('pageShow',{path:location.pathname});
    window.simpleRRWeb?.resume();
  }
};

const handleOnload=(event)=>{
  console.log('window event handleOnload ',location.pathname);

 // window.simpleRRWeb=new SimpleRRWeb({uuid:window.tracert.uuid,session_id:window.tracert.session_id});
  if(location.pathname.includes("/web/paper/report/main")) {
    window.simpleRRWeb?.start();
  }
 
};


const handleUnload = () => {
  // 在此处执行清理操作
  console.log('window event handleUnload ',location.pathname);
};

/**
 * 如果不是移动端设备设置最大宽度
 */
if (!isMobile()) {
  const { clientWidth } = document.body;
  const width = clientWidth > 500 ? 500 : clientWidth;
  const fontSize = `${width / 10}px`;

  document.body.style.maxWidth = `${width}px`;
  document.body.style.margin = "0 auto";
  document.getElementsByTagName("html")[0].style.fontSize = fontSize;
}

document.addEventListener('visibilitychange', handleVisibilityChange);
window.addEventListener("beforeunload", async function name(params) {
  // 在此处执行提示等操作
  logi('window event handleBeforeUnload ',location.pathname);
 // await window.simpleRRWeb?.uploadRRWebDataSync();
  logi('window event handleBeforeUnload  upload finish',location.pathname);
 // this.alert('ok');
});
window.addEventListener("unload", handleUnload);
window.addEventListener("load",handleOnload);


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
