import axios from "axios";
// import qs from 'qs';
import { getDomain } from "./tools";

axios.defaults.withCredentials = true;
// 发送时
axios.interceptors.request.use(
  (config) => config,
  (err) => Promise.reject(err)
);

// 响应时
axios.interceptors.response.use(
  (response) => response,
  (err) => Promise.resolve(err.response)
);

// 检查状态码
const checkStatus = (res) => {
  if (res.status === 200 || res.status === 304) {
    return res.data;
  }
  return {
    code: 0,
    msg: res.statusText,
    data: res.statusText,
  };
};

// 检查CODE值
const checkCode = (res) => {
  if (res.code === 0) {
    console.error(res.msg);
  }
  return res;
};

const domain = getDomain();
const prefix = domain?.api || "";
// const prefix = '/paper';

const get = (url, params) => {
  return axios({
    method: "get",
    url: prefix + url,
    params,
    headers: {
      channel: sessionStorage.getItem("channel") || "",
     
      media: sessionStorage.getItem("media") || "",
      media_id:
        sessionStorage.getItem("media_id") ||
        localStorage.getItem("media_id") ||
        "",
    },
    timeout: 60000,
  })
    .then(checkStatus)
    .then(checkCode);
};

const post = (url, data, headers) => {
  return axios({
    method: "post",
    url: prefix + url,
    data: JSON.stringify(data),
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      channel: sessionStorage.getItem("channel") || "",
     
      media: sessionStorage.getItem("media") || "",
      media_id:
        sessionStorage.getItem("media_id") ||
        localStorage.getItem("media_id") ||
        "",
    },
  })
    .then(checkStatus)
    .then(checkCode);
};

export default {
  get,
  post,
};
