import { getDeviceId, logi } from './tools';
import { getDomain, getRandomStr } from "./tools";
import LZString from "lz-string";
const rrweb = require('rrweb');

export default class SimpleRRWeb {
    constructor(options){

        this.uuid=options.uuid;
        this.session_id=options.session_id;

        this.eventsMatrix = [[]];
        this.recordHandler = null;
        this.totalCount=0
        this.lastEvent={}
        this.status=0;// 0 空闲状态； 1 正在录制 ； 2 暂停录制（进入后台）； 
        this.cacheSize=0;//
    }
    start () {
        const that = this;
       
        if(that.status === 1 || that.status === 2){
            that.status=1;
            return;//
        }
        that.stop();
        that.status=1;
        logi('start record');

        that.recordHandler = rrweb.record({
            emit(event, isCheckout){
                let tmpEvent=event;
                let jstr=JSON.stringify(event);
                
                if(!isCheckout && that.status !==1){
                    return;
                }
                // isCheckout 是一个标识，告诉你重新制作了快照
                if (isCheckout) {
                  
                  //  that.uploadRRWebData();

                    logi('start record new block');
                    that.eventsMatrix.push([]);
                }

                that.totalCount++;
                
                if(jstr.length>10240){
                    const pattern = /data:image\/[a-z]+;base64,[^\\]+/g;
                    
                    const result = jstr.match(pattern);
                    let imageBytes=0;
                    let ll="";
                    if(result){
                    
                    for(let i=0;i<result.length;i++){
                        imageBytes+=result[i].length; 
                        ll=ll+" "+result[i].length;
                    }

                    logi(`event bytes=${jstr.length} imgage bytes=${imageBytes} ==> ${ll}`); 
                    jstr=jstr.replace(pattern,'')
                    tmpEvent = JSON.parse(jstr);

                }

                    //logi(jstr);
                }
                logi(`totalEvents= ${that.totalCount}  eventType= ${event.type}  eventTime= ${event.timestamp} eventLength=${jstr.length}`);
                const lastEvents = that.eventsMatrix[that.eventsMatrix.length - 1];
             //   logi('got events ',lastEvents.length);
                that.cacheSize+=jstr.length;
                that.lastEvent=tmpEvent;
                lastEvents.push(tmpEvent);
                if( that.cacheSize>=1.5*1024*1024){
                    that.uploadRRWebData();
                    that.cacheSize=0;
                }
            },
            sampling: {
                // 定义不录制的鼠标交互事件类型，可以细粒度的开启或关闭对应交互录制
                mouseInteraction: false,
                mousemove: false,
                ContextMenu: false,
                DblClick: false,
                scroll: 250, // 每 250ms 最
                input: 'last', // 连续输入时，只录制最终值
                media:800,
                inline:false,
                dataURLOptions:{},
                inlineImages:false,
                collectFonts:false,
                recordCanvas:false,
                inlineStylesheet:false,

            },
            checkoutEveryNms: 10 * 60 * 1000, // 每10分钟重新制作快照
            blockClass: 'no-record',
           // packFn: rrweb.pack,
        });
    };
    
    stop(){
        this.eventsMatrix = [[]];
        if (this.recordHandler){
            this.recordHandler();
            this.recordHandler = null;
        }
    };

    pause(){
      this.status=2;  
      this.uploadRRWebData();
    }
    resume(){
        this.status=1;  
    }

    addCustomEvent(eventName,options){
      //  rrweb.record.addCustomEvent(eventName, options);
    }

    uploadData () {
        // 上传
        const motionNew = this.eventsMatrix;
        this.eventsMatrix = [[]];
		const formData = new FormData();
        
        let events=[]
        for(let i=0;i<motionNew.length;i++)
            for(let j=0;j<motionNew[i].length;j++)
                events.push(motionNew[i][j]);
        
        logi(`total events= ${events.length}`);
        if(events.length==0)
            return null;

        const date = new Date();
        const milliseconds = date.getTime();
        formData.append("uuid",this.uuid);
        formData.append("session_id",this.session_id); 
        formData.append("timestampStr",milliseconds);

        const ss= LZString.compressToBase64(JSON.stringify(events));
        const blob = new Blob([ss], { type: 'text/plain' });
        const file=new File([blob],'1.txt');
        formData.append('file',file);

        logi('befor upload json uuid= '+this.uuid+" session="+this.session_id);
        //上传
        let call=fetch(getDomain()?.data+'/rrweb/uploadJson',//'http://127.0.0.1:8100/rrweb/uploadJson',//
        {
            method: "POST",
            body:formData,
          })
          .then(response => {
            if (!response.ok) {
                throw new Error('Failed to upload file.');
            }
            return response.json(); // Assuming the server responds with JSON, change as needed
        })
        return call;
    }

    async uploadRRWebDataSync(){

        try {
            const response = await this.uploadData();
        if(response){
            const {code,message} = response;
            if (code === 0) {
                // 上传成功后继续上传
                logi('upload json sucess uuid= '+this.uuid+" session="+this.session_id);
              } else {
                 logi('upload json error uuid='+this.uuid+" session="+this.session_id,message);
              }
        }
        } catch (error) {
            logi('upload json error uuid='+this.uuid+" session="+this.session_id,error);
            logi(error);
        }
        

    }

    uploadRRWebData(callback){
        let call=this.uploadData();
        if(!call){
            callback && callback();
            return;
        }

        call?.then((res) => {

            const {code,message} = res
            if (code === 0) {
                // 上传成功后继续上传
                logi('upload json sucess uuid= '+this.uuid+" session="+this.session_id);
              } else {
                 logi('upload json error uuid='+this.uuid+" session="+this.session_id,message);
              }

              callback && callback();
          }).catch((e)=>{
            callback();
            console.log(e);
            
          });
    }
     
    
}
