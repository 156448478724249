export const request = (options = {}) => {
  return new Promise((resolve, reject) => {
    const { method, url, data } = options;
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Channel", sessionStorage.getItem("channel") || "");
    xhr.setRequestHeader(
      "Media_id",
      sessionStorage.getItem("media_id") ||
        localStorage.getItem("media_id") ||
        ""
    );
    xhr.setRequestHeader("Media", sessionStorage.getItem("media") || "");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // 请求成功
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          // 请求失败返回参数，以备后续使用
          reject(data);
        }
      }
    };
    xhr.onerror = () => {
      reject(data);
    };
    // 将JSON对象转换为字符串
    const jsonData = JSON.stringify(data);
    xhr.send(jsonData);
  });
};

export const requestFormData = (options = {}) => {
  return new Promise((resolve, reject) => {
    const { method, url, data } = options;
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader("Content-Type", "multipart/form-data");
    xhr.setRequestHeader("Channel", sessionStorage.getItem("channel") || "");
    xhr.setRequestHeader(
      "Media_id",
      sessionStorage.getItem("media_id") ||
        localStorage.getItem("media_id") ||
        ""
    );
    xhr.setRequestHeader("Media", sessionStorage.getItem("media") || "");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // 请求成功
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          // 请求失败返回参数，以备后续使用
          reject(data);
        }
      }
    };
    xhr.onerror = () => {
      reject(data);
    };
   
    xhr.send(data);
  });
};